import {Link, Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import './contact.css';
import Call from '../svg/call.svg';

const Contact = () => {
    return(
        <div className='contact' id='contact'>
            <div className='callBox'>
                <div className='callDesc'>
                    <h1 className='contactCTA'><Trans>Ready to get pain relief?</Trans></h1>
                    <h2><Trans>Give us a call to make an appointment!</Trans></h2>
                </div>
                <a href='tel:12344321' className='call'>
                    <Call></Call>
                    <div className='phoneNum'>2338-3266</div>
                </a>
            </div>
            <a href="https://goo.gl/maps/MCcBN9YAy1y158nT8" className='locateBox'>
                <h2><Trans>You can find us here!</Trans></h2>
                <p><Trans>Click to open Google Maps</Trans></p>
                <StaticImage 
                    className='gMap'
                    src='../images/map.png' 
                    alt="A map to the New Hope Chiropractic"></StaticImage>
                <div className='addressBox'>
                    <p><Trans>New Hope Chiropractic Centre</Trans></p>
                    <p><Trans>Shop 5A, G/F, Hang Shun Bldg</Trans></p>
                    <p><Trans>37-39 Tonkin Street</Trans></p>
                    <p><Trans>Cheung Sha Wan, Kowloon, Hong Kong</Trans></p>
                </div>
            </a>
        </div>
    )
}

export default Contact;