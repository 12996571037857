import {Link, Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import './about.css';

const About = () => {
    return(
        <div className='aboutContainer'>
            <div className='docText'>
                <div className='docTitle'>
                    <h2><Trans>About</Trans></h2>
                    <h1><Trans>Doctor Chi D.C.</Trans></h1>
                </div>
                <div className='docDescription'>
                    <div>
                        <h2><Trans>Qualifications</Trans></h2>
                        <ul className='certList'>
                            <li><Trans>Life Chiropractic College West, USA</Trans></li>
                            <li><Trans>Doctor of Chiropractic Graduate (Magna Cum Laude)</Trans></li>
                        </ul>
                    </div>
                    <div>
                        <h2><Trans>Certifications</Trans></h2>
                        <ul className='certList'>
                            {/* <li><Trans>COX Spinal Decompression</Trans></li>
                            <li><Trans>Graston Technique</Trans></li>
                            <li><Trans>Keniso Tape</Trans></li> */}
                            <li><Trans>National Board of Chiropractic Examiners Certificate of Attainment</Trans></li>
                            <li><Trans>Educational Commission for Foreign Medical Graduates</Trans></li>
                        </ul>
                    </div>
                </div>
            </div>
            <StaticImage
                className='drChiImg' 
                src="../images/drChi.png" 
                alt="Doctor Chi"></StaticImage>
        </div>
    )
}

export default About;