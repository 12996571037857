import {Link, Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import DownArrow from '../svg/downArrow.svg';
import './treatment.css';

const Treatments = () => {
    return(
        <div className='treatBack'>
            <h1><Trans>Our Treatments</Trans></h1>
            <div className='treatmentContainer contain1'>
                <div className='slider slider1'>
                    <div className='treatment'><Trans>Trigger Finger</Trans></div>
                    <div className='treatment'><Trans>Doublecross Syndrome</Trans></div>
                    <div className='treatment'><Trans>Sciatica</Trans></div>
                    <div className='treatment'><Trans>Knee Pain</Trans></div>
                    <div className='treatment'><Trans>Plantar Fasciitis</Trans></div>
                    <div className='treatment'><Trans>Bone Spurs</Trans></div>
                    <div className='treatment'><Trans>Arthralgias</Trans></div>
                    <div className='treatment'><Trans>Sport Injury</Trans></div>
                    <div className='treatment'><Trans>Disc Herniation</Trans></div>
                    <div className='treatment'><Trans>Tingling and Numbness</Trans></div>
                    <div className='treatment'><Trans>Spinal Canal Problem</Trans></div>
                </div>
                <div className='slider slider1'>
                    <div className='treatment'><Trans>Trigger Finger</Trans></div>
                    <div className='treatment'><Trans>Doublecross Syndrome</Trans></div>
                    <div className='treatment'><Trans>Sciatica</Trans></div>
                    <div className='treatment'><Trans>Knee Pain</Trans></div>
                    <div className='treatment'><Trans>Plantar Fasciitis</Trans></div>
                    <div className='treatment'><Trans>Bone Spurs</Trans></div>
                    <div className='treatment'><Trans>Arthralgias</Trans></div>
                    <div className='treatment'><Trans>Sport Injury</Trans></div>
                    <div className='treatment'><Trans>Disc Herniation</Trans></div>
                    <div className='treatment'><Trans>Tingling and Numbness</Trans></div>
                    <div className='treatment'><Trans>Spinal Canal Problem</Trans></div>
                </div>
            </div>
            <div className='treatmentContainer'>
                <div className='slider slider2'>
                    <div className='treatment'><Trans>Headache</Trans></div>
                    <div className='treatment'><Trans>Jaw Pain</Trans></div>
                    <div className='treatment'><Trans>Neck Pain</Trans></div>
                    <div className='treatment'><Trans>Shoulder Pain</Trans></div>
                    <div className='treatment'><Trans>Back Pain</Trans></div>
                    <div className='treatment'><Trans>Tennis Elbow</Trans></div>
                    <div className='treatment'><Trans>Wrist Pain</Trans></div>
                    <div className='treatment'><Trans>Scoliosis</Trans></div>
                    <div className='treatment'><Trans>Spinal Subluxation</Trans></div>
                    <div className='treatment'><Trans>Prolapsed Intervertebral Disc (P.I.D.)</Trans></div>
                </div>
                <div className='slider slider2'>
                    <div className='treatment'><Trans>Headache</Trans></div>
                    <div className='treatment'><Trans>Jaw Pain</Trans></div>
                    <div className='treatment'><Trans>Neck Pain</Trans></div>
                    <div className='treatment'><Trans>Shoulder Pain</Trans></div>
                    <div className='treatment'><Trans>Back Pain</Trans></div>
                    <div className='treatment'><Trans>Tennis Elbow</Trans></div>
                    <div className='treatment'><Trans>Wrist Pain</Trans></div>
                    <div className='treatment'><Trans>Scoliosis</Trans></div>
                    <div className='treatment'><Trans>Spinal Subluxation</Trans></div>
                    <div className='treatment'><Trans>Prolapsed Intervertebral Disc (P.I.D.)</Trans></div>
                </div>
            </div>
        </div>
    )
}

export default Treatments;