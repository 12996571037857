// i18next-extract-mark-ns-start index

import React from 'react';
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql, Link as GatsbyLink} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import About from '../components/about';
import Contact from '../components/contact';
import Treatments from '../components/treatments';

const IndexPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <SEO title={t('Home')} />
      <Hero></Hero>
      <About></About>
      <Treatments></Treatments>
      <Contact></Contact>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
