import {Link, Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
// import DownArrow from '../svg/downArrow.svg';
import './hero.css';

const Hero = () => {
    return(
        <div className='heroContainer'>
            <div className='hero'>
                <div className='heroText'>
                    <h1><Trans>For a painfree tomorrow.</Trans></h1>
                    <h2><Trans>Here to alleviate your pain, so you can focus on living.</Trans></h2>
                    <Link to='#contact' className='aptLink'>
                        <div className='aptButton'>
                            <Trans>Request Appointment</Trans>
                        </div>
                    </Link>
                </div>
                {/* <div className='imageContainer'> */}
                    <StaticImage 
                        className='heroImg'
                        src="../images/balloons.jpg" 
                        alt="A spine made of clouds in the sky"></StaticImage>
                {/* </div> */}
            </div>
            {/* <DownArrow className='downArrow'></DownArrow> */}
        </div>
    )
}

export default Hero;